const dummyData = {
  data: [
    // {
    //   id: 1,
    //   name: 'Tim Cook',
    //   date: '1960-11-01',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 2,
    //   name: 'Bill Gates',
    //   date: '1955-10-28',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 3,
    //   name: 'Mark Zuckerberg',
    //   date: '1984-05-14',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 4,
    //   name: 'Elon Musk',
    //   date: '1971-06-28',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 5,
    //   name: 'Larry Page',
    //   date: '1973-03-26',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 6,
    //   name: 'Jeff Bezos',
    //   date: '1964-01-12',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 7,
    //   name: 'Daniel Ek',
    //   date: '1983-02-21',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 8,
    //   name: 'Reed Hastings',
    //   date: '1960-10-08',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 9,
    //   name: 'Brian Chesky',
    //   date: '1981-08-29',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 10,
    //   name: 'Mike Cannon-Brookes',
    //   date: '1979-11-17',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 11,
    //   name: 'Chris Wanstrath',
    //   date: '1985-03-13',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 12,
    //   name: 'Brendan Eich',
    //   date: '1961-07-04',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 13,
    //   name: 'Guido van Rossum',
    //   date: '1956-01-31',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 14,
    //   name: 'Simon Willison',
    //   date: '1981-01-01',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 15,
    //   name: 'Dylan Field',
    //   date: '1992-10-18',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 16,
    //   name: 'Carly Fiorina',
    //   date: '1954-09-06',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 17,
    //   name: 'Shantanu Narayen',
    //   date: '1963-05-27',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 18,
    //   name: 'Steve Wozniak',
    //   date: '1950-08-11',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 18,
    //   name: 'Steve Chen',
    //   date: '1978-08-25',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 19,
    //   name: 'Larry Ellison',
    //   date: '1944-08-17',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 20,
    //   name: 'Brian Acton',
    //   date: '1972-02-17',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 21,
    //   name: 'Kevin Systrom',
    //   date: '1983-12-30',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 22,
    //   name: 'Linus Torvalds',
    //   date: '1969-12-28',
    //   full_date: true,
    //   event_type: 'birthday',
    //   notify: true,
    //   notification_days: 0,
    // },
    // {
    //   id: 23,
    //   name: "Michael's Graduation",
    //   date: '2025-07-19',
    //   full_date: true,
    //   event_type: 'special',
    //   notify: true,
    //   notification_days: 2,
    // },
    // {
    //   id: 24,
    //   name: 'Wedding Anniversary',
    //   date: '2024-09-14',
    //   full_date: true,
    //   event_type: 'special',
    //   notify: true,
    //   notification_days: 14,
    // },
    // {
    //   id: 25,
    //   name: 'Europe Trip',
    //   date: '2026-08-01',
    //   full_date: true,
    //   event_type: 'special',
    //   notify: false,
    //   notification_days: 25,
    // },
    // {
    //   id: 26,
    //   name: 'Road Trip Syd to Per',
    //   date: '2022-07-25',
    //   full_date: true,
    //   event_type: 'special',
    //   notify: true,
    //   notification_days: 7,
    // },
  ]
};

export default dummyData;